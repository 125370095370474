import { validatePostcode } from "@storefront/core/data-resolver/user";
import Vue from "vue";
export default Vue.extend({
    methods: {
      async validatePostcodeHouseNumber() {
        if (this.showFullAddress == false ) {
          const list = await validatePostcode(
            this.form.address.postcode,
            this.form.address.house_number_and_addition
          );
          if (list.length == 1) {
            this.foundAddresses = [];
            let address = list[0].street + " " + list[0].number;
            this.form.address.streetDisplay = list[0].street;
            this.form.address.streetDisplayState = true;
            if (list[0].letter != null) {
              this.form.address.house_number_and_addition = `${this.form.address.house_number_and_addition} ${list[0].letter}`;
              address = address + list[0].letter;
            } else {
              this.form.house_number_and_addition = `${this.form.address.house_number_and_addition}`;
            }
  
            address = address + ", " + list[0].postcode + " " + list[0].city;
  
            this.form.address.city = list[0].city;
            this.form.address.cityState = true;
            this.foundAddress = address;
            this.validatePostCode();
            this.form.address.house_number_and_additionState = true;
          } else if (list.length == 0) {
              this.foundAddresses = [];
              this.foundAddress = "";
              this.form.address.house_number_and_additionError = this.$t("postcode_not_found");
              this.form.address.house_number_and_additionState = false;
          } else if (list.length > 1) {
            if(this.foundAddresses.length > 0){
              this.foundAddresses = [];
            }
            list.forEach(function (item) {
              let address = item.street + " " + item.number;
              if(item.letter != null && item.addition != null) {
                address = address + " " + item.letter + item.addition;
              } else if (item.letter != null) {
                address = address + " " + item.letter;
              } 
  
              address = address + ", " + item.postcode + " " + item.city;
              item.text = address;
              this.foundAddresses.push(item);
            }, this);
            // Sort foundAddresses based on letter and addition
            this.foundAddresses.sort(function (a, b) {
              // Sort by letter (A, B, C, etc.)
              if (a.letter !== b.letter) {
                return a.letter.localeCompare(b.letter);
              }
  
              // If letter is the same, sort by addition (numeric order)
              return parseInt(a.addition) - parseInt(b.addition);
            });
            if(this.selectedAddress === null){
              this.form.address.house_number_and_additionState = null;
            } else {
              let addition = "";
              if(this.foundAddresses[this.selectedAddress] != undefined){
                if (this.foundAddresses[this.selectedAddress].letter != null) {
                  addition = this.foundAddresses[this.selectedAddress].letter;
                }
                if (this.foundAddresses[this.selectedAddress].addition != null) {
                  addition += this.foundAddresses[this.selectedAddress].letter !== null ? (!isNaN(this.foundAddresses[this.selectedAddress].letter) && !isNaN(this.foundAddresses[this.selectedAddress].addition) ? "-" : "") + this.foundAddresses[this.selectedAddress].addition : this.foundAddresses[this.selectedAddress].addition;
                }
                let houseNumber = `${this.foundAddresses[this.selectedAddress].number} ${addition}`;
                if(this.form.address.house_number_and_addition != houseNumber){
                  this.selectedAddress = null;
                  this.form.address.house_number_and_additionState = null;
                }
              } else{
                this.selectedAddress = null;
                this.form.address.house_number_and_additionState = null;
              }
            }
          }
        } else {
          if (this.form.address.postcode == "") {
            this.form.address.postcodeState = false;
            this.form.address.postcodeError = this.$t("postcode_required");
          } else {
            this.form.address.postcodeState = true;
          }
        }
      },
    }
})